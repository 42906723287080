//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  components: {},
  data: () => ({}),

  computed: {
    ...mapState(['aboutMe', 'about']),
    aboutWords1() {
      return this.about.text1.split(' ')
    },
    aboutWords2() {
      return this.about.text2.split(' ')
    },
    aboutWords3() {
      return this.about.text3.split(' ')
    },
    aboutWords4() {
      return this.about.text4.split(' ')
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const { aboutTextWords, aboutText } = this.$refs

      const gsap = this.$gsap

      const aboutTextWordsTl = gsap.timeline({
        scrollTrigger: {
          trigger: aboutText,
          start: 'top 85%',
          end: '50% 70%',
          scrub: true,
          once: true,
        },
      })

      aboutTextWords.forEach((word) => {
        aboutTextWordsTl.to(word, {
          onUpdate: () => word.classList.add('about__text__word--revealed'),
        })
      })
    },
  },
}
